*:focus {
  outline: none !important;
}

input.form-control:focus {
  box-shadow: none;
  border-color: $projcolor1;
}
p {
  text-indent: 1rem;
  line-height: 1.5em;
}
img {
  max-width: 100%;
  max-height: 100%;
}

h1,
.title.title-page {
  font-size: 2.25rem;
  font-family: 'Oldsman', 'Cormorantinfant', Helvetica, Arial, sans-serif;
  @include media-breakpoint-down(sm) {
    font-size: 1.95rem;
  }
}
.title {
  font-size: 1.5rem;
  color: $projcolor1;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    padding: {
      top: 0.05rem;
      left: 0;
      bottom: 0.05rem;
      right: 0;
    }
  }
  @include media-breakpoint-up(md) {
    padding: {
      top: 1rem;
      left: 0;
      bottom: 0.75rem;
      right: 0;
    }
  }
}


//----on transfer----//
.wrapMenu {
  display: flex;
  width: 100%;
  height: 100%;
  transition: margin 0.5s;
  margin: 0 0 0 -250px;
  &.is-nav-open {
    margin-left: 0;
  }
  .nav {
    position: absolute;
    width: 250px;
    height: 100vh;
    border-right: 1px solid #ccc;
    z-index: 1;
    background: $white;
    .nav__icon {
      position: absolute;
      top: 0;
      right: -60px;
      padding: 15px;
      font-size: 20px;
      cursor: pointer;
      background: transparent;
      border: none;
      transition: color 0.3s;
      &:hover,
      &:focus,
      &:active {
        color: #5eb2ff;
        box-shadow: none !important;
        background: transparent !important;
      }
    }
  }
  .ant-menu-inline{
    border:none;
  }
}
// crutch