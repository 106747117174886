@import '../base/settings';
$font-family: 'Cormorantinfant';
$file-path: '../../fonts/Cormorantinfant/Cormorantinfant';
$file-path-bold: '../../fonts/Cormorantinfant/Cormorantinfantbold';
$file-path-italic: '../../fonts/Cormorantinfant/Cormorantinfantitalic';
$font-family-title: 'Oldsman';
$file-path-title: '../../fonts/Oldsman/Oldsman';


//@include font-face($font-family-title, $file-path-title);

@include font-face($font-family, $file-path);
//@include font-face($font-family, $file-path-bold, 900, normal);
//@include font-face($font-family, $file-path-italic, normal, italic);

@for $i from 1 through 48 {
  .fs#{$i*2} {
    font-size: #{$i * 2 / $home-rem}rem;
  }
}
