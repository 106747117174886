//text-decoration
a.tdn {
  text-decoration: none;
}
a.tdn:hover {
  text-decoration: none;
}
a.td01 {
  text-decoration: none;
}
a.td01:hover {
  text-decoration: underline;
}
a.td10 {
  text-decoration: underline;
}
a.td10:hover {
  text-decoration: none;
}
//line-height
@for $i from 0 through 2 {
  .lh#{$i} {
    line-height: #{$i};
  }
}
//letter-spacing
@for $i from 0 through 2 {
  .ls#{$i} {
    letter-spacing: #{$i};
  }
}
