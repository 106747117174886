@mixin opacity($opacity: 0.5) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}

@mixin border-radius($r: 1px) {
  -webkit-border-radius: $r;
  -moz-border-radius: $r;
  border-radius: $r;
}

@mixin gradient($deg, $from: $black, $to: $white) {
  background-color: $from;
  background-image: -webkit-gradient(
    linear,
    left left,
    left right,
    from($from),
    to($to)
  );
  background-image: -webkit-linear-gradient($deg, $from, $to);
  background-image: -moz-linear-gradient($deg, $from, $to);
  background-image: -o-linear-gradient($deg, $from, $to);
  background-image: linear-gradient($deg, $from, $to);
}

// /* Внутреняя тень */
@mixin box-shadow-in($x: 0, $y: 0, $blur: 2px, $color: rgba(0, 0, 0, 0.8)) {
  -webkit-box-shadow: inset $x $y $blur $color;
  box-shadow: inset $x $y $blur $color;
}
// /* Внешняя тень */
@mixin box-shadow($x: 0, $y: 0, $blur: 2px, $color: rgba(0, 0, 0, 0.8)) {
  -webkit-box-shadow: $x $y $blur $color;
  box-shadow: $x $y $blur $color;
}

@mixin text-shadow($x: 0, $y: 0, $blur: 2px, $color: rgba(0, 0, 0, 0.8)) {
  -webkit-text-shadow: $x $y $blur $color;
  -moz-text-shadow: $x $y $blur $color;
  text-shadow: $x $y $blur $color;
}
@mixin blur($blur: 5px) {
  -webkit-filter: blur($blur);
  -moz-filter: blur($blur);
  filter: blur($blur);
}
@mixin transition($property: all, $duration: 0.3s, $function: linear) {
  -webkit-transition: $property $duration $function;
  -moz-transition: $property $duration $function;
  -o-transition: $property $duration $function;
  transition: $property $duration $function;
}

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    src: url('#{$file-path}.woff') format('woff'),
      url('#{$file-path}.ttf') format('truetype');
  }
}

@mixin btn-disabled($color1: $black, $color2: $white) {
  background: $color1;
  border-color: lighten($color1, 2%);
  color: darken($color2, 20%);
  box-shadow: none;
}
@mixin btn-hover($color) {
  background: darken($color, 10%);
  border-color: darken($color, 12%);
  box-shadow: none;
}
@mixin btn-focus($color) {
  background: darken($color, 8%);
  border-color: darken($color, 5%);
  box-shadow: 0 0 0 0.2rem lighten($color, 10%);
}
@mixin btn-active($color) {
  background: darken($color, 8%) !important;
  border-color: darken($color, 5%) !important;
  box-shadow: 0 0 0 0.2rem lighten($color, 10%) !important;
}

//--media query--//
$min10: 375px;
$min6p: 414px;
$max10: 812px;
$max6: 667px;
$max6p: 736px;
@mixin imedia(
  $min10: $min10,
  $max10: $max10,
  $max6: $max6,
  $min6p: $min6p,
  $max6p: $max6p,
  $o: portrait,
  $ratio: 2
) {
  @media only screen and (width: $min6p) and (height: $max6p) and (-webkit-min-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min6p) and (height: $max6p) and (min--moz-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min6p) and (height: $max6p) and (-o-min-device-pixel-ratio: $ratio/1) and (orientation: $o),
    only screen and (width: $min6p) and (height: $max6p) and (min-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min6p) and (height: $max6p) and (min-resolution: 192dpi) and (orientation: $o),
    only screen and (width: $min6p) and (height: $max6p) and (min-resolution: 2dppx) and (orientation: $o),
    only screen and (width: $min10) and (height: $max6) and (-webkit-min-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min10) and (height: $max6) and (min--moz-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min10) and (height: $max6) and (-o-min-device-pixel-ratio: $ratio/1) and (orientation: $o),
    only screen and (width: $min10) and (height: $max6) and (min-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min10) and (height: $max6) and (min-resolution: 192dpi) and (orientation: $o),
    only screen and (width: $min10) and (height: $max6) and (min-resolution: 2dppx) and (orientation: $o),
    only screen and (width: $min10) and (height: $max10) and (-webkit-min-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min10) and (height: $max10) and (min--moz-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min10) and (height: $max10) and (-o-min-device-pixel-ratio: $ratio/1) and (orientation: $o),
    only screen and (width: $min10) and (height: $max10) and (min-device-pixel-ratio: $ratio) and (orientation: $o),
    only screen and (width: $min10) and (height: $max10) and (min-resolution: 192dpi) and (orientation: $o),
    only screen and (width: $min10) and (height: $max10) and (min-resolution: 2dppx) and (orientation: $o) {
    @content;
  }
}

@mixin imedia10($min10: $min10, $max10: $max10, $ratio: 3) {
  @media only screen and (min-width: $min10) and (max-height: $max10) and (-webkit-min-device-pixel-ratio: $ratio) {
    @content;
  }
}

@mixin imedia6($min10, $max6, $ratio: 2, $o: portrait) {
  @media only screen and (min-width: $min10) and (max-height: $max6) and (-webkit-min-device-pixel-ratio: $ratio) and (orientation: $o) {
    @content;
  }
}

@mixin imedia6p($min6p, $max6p, $ratio: 3, $o: portrait) {
  @media only screen and (min-width: $min6p) and (max-height: $max6p) and (-webkit-min-device-pixel-ratio: $ratio) and (orientation: $o) {
    @content;
  }
}
