$projcolor1: rgba(77, 0, 0, 1);
$projcolor2: rgba(147, 136, 116, 1);
$projcolor3: rgba(77, 0, 0, 1);
$projcolor4: rgba(240, 240, 240, 1);
$projcolor5: rgba(236, 236, 236, 1);
$projcolor6: rgba(167, 125, 125, 1);
$projcolor7: rgba(192, 151, 140, 1);
$projcolor8: rgba(183, 186, 171, 0.3);
$projcolor9: rgba(100, 100, 100, 0.26);

$number-colors: (
  1: $projcolor1,
  2: $projcolor2,
  3: $projcolor3,
  4: $projcolor4,
  5: $projcolor5,
  6: $projcolor6,
  7: $projcolor7,
  8: $projcolor8,
  9: $projcolor9,
);

$white: #fefefe;
$home-rem: 16;
$vertical-gutter: 14px;
